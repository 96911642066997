import { memo } from 'react'
import dynamic from 'next/dynamic'
import { useStoryblokState } from '@storyblok/react'
import StoryblokPage from '@utils/storyblok/page'
import { withGetStaticProps } from '@utils/helpers/withGetStaticProps'
import { SITE_ADDRESS, SITE_CONTACT } from '@constants/siteInfo'
import { useRouter } from 'next/router'
// Lazy load components
const SiteJsonLd = dynamic(() => import('@components/SEO/SiteJsonLd'))
const StoryblokComponent = dynamic(
  () => import('@components/Storyblok/StoryblokComponent'),
)

// Memoized Home component to prevent unnecessary re-renders
const Home = memo(({ story, domain, address, contact }) => {
  const router = useRouter()
  const storyState = useStoryblokState(story)
  if (!storyState?.content) {
    return null
  }

  return (
    <>
      <SiteJsonLd
        domain={domain}
        locales={router.query.locales}
        address={address}
        contact={contact}
      />
      <StoryblokComponent blok={storyState.content} key={storyState.id} />
    </>
  )
})

Home.displayName = 'Home'

export const getStaticProps = withGetStaticProps(async ({ params }) => {
  const { locales } = params

  if (!/^[a-z]{2}-[a-z]{2}-[a-z]{3}$/.test(locales)) {
    return {
      notFound: true,
      revalidate: 30,
    }
  }

  const language = locales.split('-')[0]
  const page = await StoryblokPage({ language, slug: 'storyblok' })

  if (!page) {
    return {
      notFound: true,
      revalidate: 30,
    }
  }

  return {
    props: {
      story: page.story,
      type: 'homepage',
      key: page.story.id,
      updated: new Date().toISOString(),
      address: SITE_ADDRESS,
      contact: SITE_CONTACT,
    },
    revalidate: 3600,
  }
})

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default Home
